export const environment = {
  production: false,
  staging: false,
  qa: true,
  dev: false,
  local: false,
  TOKEN_KEY: 'sb-folweyujcwqbdzpjkdjs-auth-token',
  APP_URL: 'https://qaplatform.visions.team',
  API_URL: 'https://visionqa-api.visions.team',
  SUPABASE: {
    SUPABASE_URL: 'https://folweyujcwqbdzpjkdjs.supabase.co',
    // eslint-disable-next-line max-len
    SUPABASE_KEY:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZvbHdleXVqY3dxYmR6cGprZGpzIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcwMTg3NDA3MiwiZXhwIjoyMDE3NDUwMDcyfQ.oVAWbzpSrPyIyIpRFPF5SNJo8vB9LTyZqVyooRUQF98',
  },
};
